<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="location-hours-wrapper">
    <div class="d-flex location-hours-custom">
      <div class="map-pin-icon">
        <MapPinIcon/>
      </div>
      <div class="info-text-item"> {{ $t(service + '-services-location') }}</div>
    </div>
    <div class="d-flex align-items-center">
      <WorkingHoursIcon/>
      <div class="info-text-item"> {{ $t(service + '-working-days') }}</div>
    </div>
    <div class="info-text-item no-icon"> {{ $t(service + '-days-off') }}</div>
  </div>
</template>
