import MapPinIcon from "@/components/Common/Icons/MapPinIcon";
import WorkingHoursIcon from "@/components/Common/Icons/WorkingHoursIcon";

export default {
    name: 'CleaningLocationHoursInfo',
    props: {
        service: {
            type: String
        }
    },
    components: {
        MapPinIcon,
        WorkingHoursIcon
    },
    mounted() {
    }
}
